import {create} from "zustand";
import axios from "axios";
import { message } from "antd";

export let useModels = create((set, get) => ({
    models: [],
    getModels: (ltid, brand) => {
        axios.get('models/' + ltid + '/' + brand).then(res => {
            if (res.status === 200) {
                set({models: res.data})
            }
        }).catch((error)=>{
            message.info("Iltimos Kerakli Brendni tanlang")
        })
    },
    addModel: (v, ltid, brand) => {
        axios.post('models', v).then(res => {
            if (res.ok) {
                    get().getModels(ltid, brand)
            }
        })
    }
}))