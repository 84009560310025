import {MainTable} from "../../../Components/MainTable/MainTable";
import {useEffect, useState} from "react";
import axios from "axios";
import {AddModifications} from "./AddModifications";

export const Modifications = ({model}) => {
    let [rend, setRend] = useState(false)
    let [modif, setModif] = useState([])
    let columns = [
        {title: "Model Nomi", dataIndex: 'modelname', key: 'modelname'},
        {title: "O'zbekcha nomi", dataIndex: 'nameuz', key: 'nameuz'},
        {title: "Ruscha nomi", dataIndex: 'nameru', key: 'nameru'},
        {title: "Tortuvchi tomoni", dataIndex: 'pullingSide', key: 'pullingSide'},
        {title: "Ot kuchi", dataIndex: 'horsepower', key: 'horsepower'},
        {title: "Max Tezligi", dataIndex: 'maxspeed', key: 'maxspeed'},
        {title: "Eni (mm)", dataIndex: 'x', key: 'x'},
        {title: "Uzunligi (mm)", dataIndex: 'y', key: 'y'},
        {title: "Balandligi (mm)", dataIndex: 'z', key: 'z'},
        {title: "Klirans", dataIndex: 'clirans', key: 'clirans'},
        {title: "Bagaj sig'imi", dataIndex: 'backpacksize', key: 'backpacksize'},
        {title: "Kuzov turi", dataIndex: 'carbodyname', key: 'carbodyname'},
        {title: "Batafsil O'zbekcha", dataIndex: 'descriptionuz', key: 'descriptionuz'},
        {title: "Batafsil Ruscha", dataIndex: 'descriptionru', key: 'descriptionru'},

    ]
    useEffect(() => {
        axios.get('modifications/' + model).then(res => {
            if (res.status === 200) {
                setModif(res.data)
            }
        })
    }, [rend, model]);

    let onDel = (_, rec) => {
        console.log(rec)
        axios.delete('modifications/' + rec.id).then(res => {
            setRend(!rend)
        })
    }

    return (
        <>
            <MainTable
                defkey={'id'}
                pdata={modif}
                pcolumns={columns}
                pageTitle={"Modifikatsiyalar"}
                add={<AddModifications rend={rend} setRend={setRend} model={model}/>}
                xwidth={2500}
                onDelete={onDel}
            />
        </>
    )
}