import axios from "axios";
import { create } from "zustand";
import { Image, message } from 'antd';

export let useListingTypes = create((set, get) => ({
    columns: [
        {
            title: 'Ikonka',
            dataIndex: 'icon',
            key: 'icon',
            width: 100,
            render: (_, rec) => <Image style={{ width: '50px' }} src={"https://avto-baraka.test" + rec.icon} />
        },
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: 'Название на русском',
            dataIndex: 'nameru',
            key: 'nameru',
        },
    ],
    data: [],
    getData: () => {
        axios.get('/listingtypes').then(res => set({ data: res.data }))
    },
    setData: (data) => {
        axios.post('/listingtypes', data).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumot yuklashda hatolik"))
    },
    delData: (_, id) => {
        axios.delete('/listingtypes/' + id.id).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumotni o'chirishda hatolik"))
    },
    editData: (values) => {
        axios.put('/listingtypes/' + values.id, values).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumot yangilashda hatolik"))
    }
}))