import {Button, Form, Input} from "antd";
import React from "react";
import {useCats} from "./CatState";

export const Add = ({ltid}) => {

    let {addCategory, onFinishFailed} = useCats()

    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(v)=>addCategory(v, ltid.typeid)}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}