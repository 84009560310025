import React from 'react';
import {ArrowLeftOutlined} from '@ant-design/icons'
import {Button, Tabs} from 'antd'
import {Link} from 'react-router-dom';
import {Categorys} from "./Categorys/Categorys";
import {Models} from "./Modellar/Models";
import {ListToBrand} from "./ListToBrand/ListToBrand";

function ListParam(props) {

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', gap: '15px', marginBottom: '15px'}}>
                <Link to="/dash/listparams"><Button icon={<ArrowLeftOutlined/>}/></Link>
                <h3>E'lon parametri</h3>
            </div>


            <Tabs
                type="card"
                items={
                    [
                        {
                            key: 1,
                            label: "Kategoriyalar",
                            children: <Categorys/>,
                        },
                        {
                            key: 2,
                            label: "Brend Biriktirish",
                            children: <ListToBrand/>,
                        },
                        {
                            key: 3,
                            label: "Texnika modellarini qo'shish",
                            children: <Models/>,
                        },

                        {
                            key: 4,
                            label: "Nimadir qo'shish",
                            children: "Salom dunyo",
                        }
                    ]
                }
            />

        </div>
    );
}

export default ListParam;