import axios from "axios";
import {create} from "zustand";
import {Image, message} from 'antd';

export let useCurrency = create((set, get) => ({
    columns: [
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: 'Название на русском',
            dataIndex: 'nameru',
            key: 'nameru',
        },
        {
            title: 'Valyuta kodi',
            dataIndex: 'shortitem',
            key: 'shortitem',
        },
        {
            title: "So'mga nisbatan kursi",
            dataIndex: 'kurs',
            key: 'kurs',
        },
    ],
    data: [],
    getData: () => {
        axios.get('/currency').then(res => set({data: res.data}))
    },
    setData: (data) => {
        axios.post('/currency', data).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumot yuklashda hatolik"))
    },
    delData: (_, id) => {
        axios.delete('/currency/' + id.id).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumotni o'chirishda hatolik"))
    },
    editData: (values) => {
        axios.put('/currency/' + values.id, values).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumot yangilashda hatolik"))
    }
}))