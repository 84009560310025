import {create} from "zustand";
import axios from "axios";
import {Image} from "antd";

export let useLBrand = create((set, get) => ({
    brands: [],
    lbrands: [],
    columns: [
        {
            title: 'Logotip',
            dataIndex: 'logo',
            key: 'logo',
            width: 100,
            render: (_, rec) => <Image src={"https://avto-baraka.uz" + rec.logo}
                                       style={{width: '50px', height: '50px'}}/>
        },
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: 'Название на русском',
            dataIndex: 'nameru',
            key: 'nameru',
        },
    ],
    getBrands: () => {
        axios.get('brands').then(res => {
            if (res.status === 200) {
                set({brands: res.data})
            }
        })
    },
    getLBrands: (ltype) => {
        axios.get('ltype-brand/' + ltype).then(res => {
            if (res.status === 200) {
                set({lbrands: res.data})
            }
        })
    },
    setLBrands: (v, ltb) => {
        axios.post('ltype-brand', v).then(res => {
            if (res.status === 200) {
                get().getLBrands(ltb)
            }
        })
    },
    onDel: (id, ltb) => {
        axios.delete('ltype-brand/' + id).then(res => {
            if (res.status === 200) {
                get().getLBrands(ltb)
            }
        })
    }
}))