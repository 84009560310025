import { Button, Form, Input, message, Space, Switch } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "antd/es/form/Form";

export const UserActivPay = () => {
    let [payStatus, setPayStatus] = useState(false)
    let [aform] = useForm()
    let [rend, setRend] = useState(true)
    useEffect(() => {
        axios.get('user-active-amount').then(res => {
            if (res.status === 200) aform.setFieldsValue(res.data)
        })
        axios.get('getConfigs').then(res => {
            if (res.status === 200) {
                let data = res.data.filter(d => d.kalit === 'systemStartPayStatus')
                setPayStatus(Boolean(Number(data[0].qiymat)))
            }
        }).catch(error => {
            message.error("So'rovni yuborishda hatolik yuzaga keldi")
        })
    }, [rend]);


    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        let data = {
            kalit: 'systemStartPayStatus',
            qiymat: checked
        }
        console.log(data);
        axios.post('updateConfigs', data).then(res => {
            if (res.status === 200) {
                setRend(!rend)
                setPayStatus(checked)
            }
        }).catch(error => {
            console.log(error);
        })

    };

    return (
        <>
            <Form
                form={aform}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={(v) => {
                    axios.post('setuseramount', v).then(res => {
                        if (res.status === 200) {
                            message.success('Summa miqdori yangilandi!!!')
                        }
                    })
                }}
            >
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 100px' }}>
                    <Form.Item
                        hidden={true}
                        name="kalit"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Foydalanuvchini aktivlashtirish summasi"
                        name="qiymat"
                        rules={[
                            {

                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input disabled={payStatus} type={'number'} />
                    </Form.Item>
                    <Form.Item label={' '}>
                        <Button type="primary" htmlType="submit">
                            Saqlash
                        </Button>
                    </Form.Item>
                </div>

            </Form>

            <Space style={{ marginTop: '15px' }} >
                <Switch checked={payStatus} onChange={onChange} />
                <span>Boshlang'ich to'lovni <b>o'chirib</b> qo'yish.</span>
            </Space>
        </>
    )
}