import {MainTable} from "../../Components/MainTable/MainTable";
import {useEffect} from "react";
import {useTransmissions} from "./TransmissState";
import {Add} from "./Add";
import {Edit} from "./Edit";

export const Transmission = () => {
    let {setTransmissions, setEd, transmissions, columns, onDelete} = useTransmissions()
    useEffect(() => {
        setTransmissions()
    }, []);

    let onEdit = (ed)=>{
        // console.log(ed)
        setEd(ed)
    }

    return (
        <>
            <MainTable
                pageTitle={'Transmissiya turi'}
                defkey={'id'}
                pdata={transmissions}
                pcolumns={columns}
                add={<Add />}
                onDelete={onDelete}
                edit={<Edit />}
                onEdit={onEdit}
                setEd={setEd}
            />
        </>
    )
}