import {create} from "zustand";
import axios from "axios";

export let useTransmissions = create((set, get) => ({
    transmissions: [],
    ed: {},
    columns: [
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: "Nomi Rus tilida",
            dataIndex: 'nameru',
            key: 'nameru',
        },
    ],
    setTransmissions: () => {
        axios.get('transmissions').then(res => {
            if (res.data) {
                set({transmissions: res.data})
            }
        })
    },

    addTransmissions: (v) => {
        axios.post('transmissions', v).then(res => {
            if (res.status === 200) {
                get().setTransmissions()
            }
        })
    },

    onDelete: (_, rec) => {
        axios.delete('transmissions/' + rec.id).then(res => {
            if (res.status === 200) {
                get().setTransmissions()
            }
        })
    },

    edit: (v) => {
        axios.put('transmissions/' + v.id, v).then(res => {
            if (res.status === 200) {
                get().setTransmissions()
            }
        })
    },
    setEd: (ed)=>{
        console.log(ed)
        set({ed: ed})
    }
}))