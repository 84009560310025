import {Button, Form, Input, message} from "antd";
import React, {useEffect} from "react";
import {useTransmissions} from "./TransmissState";
import {useForm} from "antd/es/form/Form";

export const Edit = () => {
    let {edit, ed} = useTransmissions()
    let [form] = useForm()

    useEffect(() => {
        form.setFieldsValue(ed)
    }, [ed]);

    const onFinishFailed = (errorInfo) => {
        message.error("Ma'lumotlarni to'ldirishda hatolik");
    };
    return (
        <>

            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={edit}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="id"
                    hidden={true}
                >
                    <Input/>
                </Form.Item> <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>

        </>
    )
}