import { create } from "zustand";
import axios from "axios";
import { render } from "@testing-library/react";
import { Image, Select } from "antd";

export let useSellers = create((set, get) => ({
    sellers: [],
    columns: [
        {
            title: "Rasmi",
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (_, rec) => <Image src={rec.image} width={50} height={50} style={{ borderRadius: '50%', backgroundColor: '#f5f5f5' }} />
        },
        {
            title: "Telefon",
            dataIndex: 'phone',
            key: 'phone',
        },
        // {
        //     title: "FISH",
        //     dataIndex: 'fullname',
        //     key: 'fullname',
        //     render: (_, rec)=>{
        //         if(rec.fullname){
        //             return <>{rec.fullname}</>
        //         }else{
        //             return <>Foydalanuvchi o'z ismini ko'rsatmagan!!!</>
        //         }
        //     }
        // },
        {
            title: "To'lov Holati",
            dataIndex: 'paid',
            key: 'paid',
            render: (_, rec) => {
                return (
                    <Select
                        onSelect={(e) => {
                            get().setSellerStatus(e, rec.id)
                        }}
                        value={rec.paid}
                        options={[
                            {
                                label: "To'lov amalga oshirgan",
                                value: 1,
                            },
                            {
                                label: "To'lov amalga oshirmagan",
                                value: 0,
                            },

                        ]}
                    />
                )
            }
        },
    ],

    getSellers: () => {
        axios.get('sellers').then(res => {
            if (res.status === 200) {
                set({ sellers: res.data })
            }
        })
    },
    setSellerStatus: (status, id) => {
        axios.put('sellers/' + id, { paid: status }).then(res => {
            if (res.status === 200) {
                get().getSellers()
            }
        })
    },
    deleteSeller: (id) => {
        axios.delete('sellers/' + id).then(res => {
            if (res.status === 200) {
                get().getSellers()
            }
        })
    }
}))