import {MainTable} from "../../Components/MainTable/MainTable";
import {Add} from "./Add";
import {Edit} from "./Edit";
import {useEffect, useState} from "react";
import {useCurrency} from "./State";

export const Valyutas = () => {

    let [ed, setEd] = useState({})
    let {data, columns, getData, delData} = useCurrency();
    useEffect(getData, [])

    return (
        <>
            <MainTable
                pdata={data}
                pcolumns={columns}
                pageTitle={"Valyuta turlari"}
                defkey={'id'}
                add={<Add/>}
                onDelete={delData}
                xwidth={1000}
                onEdit={(data) => setEd(data)}
                edit={<Edit ed={ed}/>}
                setEd={setEd}
            />
        </>
    )
}