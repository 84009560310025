import {create} from "zustand";
import axios from "axios";

export let useCarBody = create((set, get) => ({
    carbodys: [],
    ltypes: [],
    ed: {},
    columns: [
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: "Nomi Rus tilida",
            dataIndex: 'nameru',
            key: 'nameru',
        },
        {
            title: "E'lon turi",
            dataIndex: 'ltypenameuz',
            key: 'ltypenameuz',
        }
    ],
    setCarBodys: () => {
        axios.get('carbodys').then(res => {
            if (res.data) {
                set({carbodys: res.data})
            }
        })
    },
    getLTypes: () => {
        axios.get('listingtypes').then(res => {
            set({ltypes: res.data})
        })
    },

    addBodys: (v) => {
        axios.post('carbodys', v).then(res => {
            if (res.status === 200) {
                get().setCarBodys()
            }
        })
    },

    onDelete: (_, rec) => {
        axios.delete('carbodys/' + rec.id).then(res => {
            if (res.status === 200) {
                get().setCarBodys()
            }
        })
    },

    edit: (v) => {
        axios.put('carbodys/' + v.id, v).then(res => {
            if (res.status === 200) {
                get().setCarBodys()
            }
        })
    },
    setEd: (ed)=>{
        console.log(ed)
        set({ed: ed})
    }
}))