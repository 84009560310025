import React, { useEffect } from 'react';
import { Card, Meta } from 'antd'
import { useListingTypes } from '../ListingTypes/Store';
import { Link } from 'react-router-dom';
function ListingParams(props) {

    let { getData, data } = useListingTypes()
    useEffect(getData, [])


    return (
        <div>
            <h3 style={{ marginBottom: '25px' }}>E'lonlar parametrlari</h3>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px,  1fr))', gap: '15px' }}>
                {data.map((d) => {
                    return (
                        <Link key={d.id} to={'/dash/listparam/' + d.id}>
                            <Card
                                style={{ textAlign: 'center' }}
                                hoverable
                                cover={<div style={{ padding: '15px' }}><img style={{ width: '100px' }} alt="example" src={"https://avto-baraka.test" + d.icon} /></div>}
                            >
                                <h3>{d.nameuz}</h3>
                            </Card>
                        </Link>
                    )
                })}

            </div>
        </div>
    );
}

export default ListingParams;