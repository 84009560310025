import {Button, Card, Col, Divider, Form, Input, message, Row} from "antd";
import axios from "axios";
import {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";

export const Tarifs = () => {

    let [tarifs, setTarifs] = useState([])
    let [rend, setRend] = useState(true)

    useEffect(() => {
        axios.get('tarif').then(res => {
            if (res.status === 200)  setTarifs(res.data)
        })
    }, [rend]);

    let [tform] = useForm()

    return (
        <div style={{boxShadow: 'inset 0 0 10px -5px black', padding: '15px', borderRadius: '10px'}}>
            <Form
                form={tform}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={(v) => {
                    axios.post('tarif', v).then(res => {
                        if (res.status === 200) {
                            message.success("Yangi tarif Qo'shildi!!!")
                            setRend(!rend)
                        }
                    })
                }}
            >
                <fieldset>
                    <legend>Tarif joylash</legend>
                    <Row gutter={15}>
                        <Col span={24}>
                            <Form.Item
                                label="Tarif nomi"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="TOPda ushlash kunlari soni"
                                name="topdays"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="VIP status belgilanadigan kunlar soni"
                                name="vipdays"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Narxi"
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={' '}>
                                <Button type="primary" htmlType="submit">
                                    Saqlash
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>
            </Form>
            <Divider/>
            <h3 style={{marginBottom: '15px'}}>Tariflar</h3>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '15px'}}>
                {
                    tarifs?.map(t => {
                        return (
                            <Card
                                key={t.id}
                                title={t.name}
                                extra={<Button onClick={()=>{
                                    axios.delete('tarif/' + t.id).then(res=>{
                                        if(res.status === 200){
                                            message.success("Tarif o'chirildi")
                                            setRend(!rend)
                                        }
                                    })
                                }} type={'primary'} danger>O'chirish</Button>}
                            >
                                <ul>
                                    <li>TOPda turish: {t.topdays} kun</li>
                                    <li>VIPda turish: {t.vipdays} kun</li>
                                    <li style={{fontWeight: '700', color: 'blue', fontSize: '20px'}}>Narxi: {t.price} so'm</li>
                                </ul>
                            </Card>
                        )
                    })
                }
            </div>
        </div>
    )
}