import {Button, Form, Input} from "antd";
import React, {useEffect} from "react";
import {useSellerTypes} from "./State";

export const Edit = ({ed}) => {

    let {editData} = useSellerTypes()
    const onFinish = (values) => {
        editData(values)
    };

    let [form] = Form.useForm()
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(()=>{
        form.setFieldsValue(ed)
    }, [ed])

    return (
        <>
            <Form
                name="basic"
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Ruscha nomi"
                    name="id"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input/>

                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}