import axios from "axios";
import {create} from "zustand";
import {Image, message} from 'antd';

export let useBrand = create((set, get) => ({
    columns: [
        {
            title: 'Logotip',
            dataIndex: 'logo',
            key: 'logo',
            width: 100,
            render: (_, rec)=><Image src={"https://avto-baraka.uz" + rec.logo} style={{width: '50px', height: '50px'}} />
        },
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: 'Название на русском',
            dataIndex: 'nameru',
            key: 'nameru',
        },
        {
            title: "Batafsil ma'lumot",
            dataIndex: 'descriptionuz',
            key: 'descriptionuz',
        },
        {
            title: "Подробная информация",
            dataIndex: 'descriptionru',
            key: 'descriptionru',
        },
    ],
    data: [],
    getData: () => {
        axios.get('/brands').then(res => set({data: res.data}))
    },
    setData: (data) => {
        axios.post('/brands', data).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumot yuklashda hatolik"))
    },
    delData: (_, id) => {
        axios.delete('/brands/' + id.id).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumotni o'chirishda hatolik"))
    },
    editData: (values) => {
        axios.put('/brands/' + values.id, values).then(res => (res.status === 200) ? get().getData() : message.error("Ma'lumot yangilashda hatolik"))
    }
}))