import {
    AppstoreAddOutlined,
    AudioMutedOutlined,
    AudioOutlined,
    CarOutlined,
    DashboardOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import { Button, Divider, Layout, Menu, Space } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content, Header } from 'antd/es/layout/layout';
import axios from 'axios';
import logo from './../../assets/images/logo.png'
import React, { useState } from 'react';
import { NavLink, Route, Routes, useHref, useNavigate } from 'react-router-dom';
import Regions from '../../Pages/Regions/Regions';
import Districts from '../../Pages/Districts/Districts';
import ListingTypes from '../../Pages/ListingTypes/ListingTypes';
import ListingParams from '../../Pages/ListingParams/ListingParams';
import ListParam from '../../Pages/ListingParams/ListParam';
import { Valyutas } from "../../Pages/Valyutas/Valyutas";
import { TypeOfFuels } from "../../Pages/TypeOfFeuls/TypeOfFuels";
import { SellerTypes } from "../../Pages/SellerTypes/SellerTypes";
import { Countrys } from "../../Pages/Countrys/Countrys";
import { Colors } from "../../Pages/Colors/Colors";
import { Brand } from "../../Pages/Brands/Brand";
import { CarBody } from "../../Pages/CarBodys/CarBody";
import { Transmission } from "../../Pages/Transmissions/Transmission";
import { Sellers } from "../../Pages/Sellers/Sellers";
import { Listings } from '../../Pages/Listings/Listings';
import { Pays } from "../../Pages/Pays/Pays";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import Credit from '../../Pages/Credit/Credit';
import Notif from '../../Components/Notif/Notif';
import notificationSound from './../../assets/sound.wav'
import Deactive from '../../Pages/Listings/Deactive';

function Dash() {
    const [collapsed, setCollapsed] = useState(false);
    let [audioStatus, setAudioStatus] = useState(false)
    const [audio] = useState(new Audio(notificationSound));
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('_auth_type') + ' ' + localStorage.getItem('_auth');

    let path = useHref()
    let navigate = useNavigate()
    let signout = useSignOut()


    const enableAudioPlayback = () => {
        setAudioStatus(!audioStatus);
        audio.play().catch((error) => {
            console.error('Error enabling audio playback:', error);
        });
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider style={{ backgroundColor: '#fff' }} trigger={null} collapsible collapsed={collapsed}>
                <div style={{ height: '40px', padding: '5px 10px', display: 'flex', alignItems: 'center', gap: '15px' }}>
                    <img src={logo} style={{ height: '30px' }} alt="Avto Baraka" /><span
                        style={{ fontSize: '20px', display: collapsed ? 'none' : 'inline' }}>BARAKA</span>
                </div>
                <Menu
                    theme="light"
                    mode="inline"

                    defaultSelectedKeys={path}
                    style={{ backgroundColor: '#fff' }}
                    items={[
                        {
                            key: '/dash/',
                            icon: <DashboardOutlined />,
                            label: <NavLink to={'/dash/'}>Tizimdagi e'lonlar</NavLink>,
                        },
                        {
                            key: '2',
                            icon: <CarOutlined />,
                            label: "E'lonlar",
                            children: [
                                {
                                    key: '/dash/listparams',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/listparams'}>E'lon parametrlari</NavLink>,
                                },
                                {
                                    key: '/dash/listingtypes',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/listingtypes'}>E'lon turlari</NavLink>,
                                },

                            ]
                        },
                        {
                            key: '/dash/sellers',
                            icon: <AppstoreAddOutlined />,
                            label: <NavLink to={'/dash/sellers'}>Tizim Foydalanuvchilari</NavLink>,
                        },
                        {
                            key: '3',
                            icon: <AppstoreAddOutlined />,
                            label: "Parametrlar",
                            children: [

                                {
                                    key: '/dash/brands',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/brands'}>Brendlar</NavLink>,
                                }, {
                                    key: '/dash/regions',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/regions'}>Viloyatlar</NavLink>,
                                },
                                {
                                    key: '/dash/districts',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/districts'}>Tumanlar</NavLink>,
                                },
                                {
                                    key: '/dash/colors',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/colors'}>Ranglar</NavLink>,
                                },
                                {
                                    key: '/dash/countrys',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/countrys'}>Davlatlar</NavLink>,
                                },
                                {
                                    key: '/dash/seller-types',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/seller-types'}>Sotuvchilar turlari</NavLink>,
                                },
                                {
                                    key: '/dash/fuels',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/fuels'}>Yoqilg'i turlari</NavLink>,
                                },
                                {
                                    key: '/dash/currencys',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/currencys'}>Valyuta turlari</NavLink>,
                                },
                                {
                                    key: '/dash/carbodys',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/carbodys'}>Kuzov Turi</NavLink>,
                                },
                                {
                                    key: '/dash/transmission',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/transmission'}>Transmissiya Turi</NavLink>,
                                },

                            ]
                        },
                        {
                            key: '4',
                            icon: <AppstoreAddOutlined />,
                            label: "Sozlamalar",
                            children: [

                                {
                                    key: '/dash/paysettings',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/paysettings'}>To'lovlar va Tariflar</NavLink>,
                                },
                                {
                                    key: '/dash/credits',
                                    icon: <AppstoreAddOutlined />,
                                    label: <NavLink to={'/dash/credits'}>Kredit sozlamalari</NavLink>,
                                }
                            ]
                        },

                    ]}
                />
                <div style={{ position: 'absolute', bottom: '10px', padding: '20px' }}>
                    <Divider />
                    <Button onClick={enableAudioPlayback} type='primary' danger={audioStatus} block icon={
                        audioStatus ? <AudioOutlined /> : <AudioMutedOutlined />
                    }>{
                            audioStatus ? 'Ovozni o\'chirish' : 'Ovozni yoqish'
                        }</Button>
                </div>
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: '0 15px',
                        height: '40px',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Space align='center'>



                        <Notif audio={audio} audioStatus={audioStatus} />
                        <Button onClick={() => {
                            signout()
                            navigate('/login')
                        }} type='primary' danger icon={<LogoutOutlined />} shape='circle' />
                    </Space>

                </Header>
                <Content
                    style={{
                        margin: '10px',
                        padding: 24,
                        minHeight: 280,
                        backgroundColor: '#fff',
                        borderRadius: '15px',
                        overflow: 'auto',

                    }}
                >
                    <Routes>
                        <Route path='/' element={<Listings />} />
                        <Route path='/deactive' element={<Deactive />} />
                        <Route path='/regions' element={<Regions />} />
                        <Route path='/districts' element={<Districts />} />
                        <Route path='/brands' element={<Brand />} />
                        <Route path='/listingtypes' element={<ListingTypes />} />
                        <Route path='/currencys' element={<Valyutas />} />
                        <Route path='/fuels' element={<TypeOfFuels />} />
                        <Route path='/seller-types' element={<SellerTypes />} />
                        <Route path='/countrys' element={<Countrys />} />
                        <Route path='/colors' element={<Colors />} />
                        <Route path='/listparams' element={<ListingParams />} />
                        <Route path='/listparam/:typeid' element={<ListParam />} />
                        <Route path='/carbodys' element={<CarBody />} />
                        <Route path='/transmission' element={<Transmission />} />
                        <Route path='/sellers' element={<Sellers />} />
                        <Route path='/paysettings' element={<Pays />} />
                        <Route path='/credits' element={<Credit />} />
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Dash;