import { CalendarOutlined, CheckSquareOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Card, Carousel, Col, DatePicker, Descriptions, Divider, Image, Input, Modal, Popover, Row, Select, Space, message } from 'antd';
import React, { useState } from 'react';
import css from './Listing.module.css'
import axios from 'axios';
function ListingCard({ listing, rend, setRend, payStatus }) {
    let [listImages, setListImages] = useState(JSON.parse(listing?.carImage))
    let [delpopopen, setDelpopopen] = useState(false)
    let [modal, setModal] = useState(false)
    let t = new Date(listing.posted_date * 1000)

    let expDate = new Date(listing.expire_date * 1000)
    // console.log(expDate);
    let onDelete = (id) => {
        axios.delete('del-listing/' + id).then(res => {
            if (res.status === 200) {
                message.success("E'lon muvaffaqiyatli o'chirildi!!!")
                setRend(!rend)
                setDelpopopen(false)
            }
        }).catch(err => {
            message.error("Xabarni o'chirishda hatolik yuzaga keldi!!!")
        })
    }
    return (

        <Card
            style={{ textAlign: 'center', padding: '0px 10px' }}
            title={
                <div style={{ display: 'grid', gridTemplateColumns: '5fr 3fr' }}>
                    <h5 style={{
                        padding: '3px 15px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '5px 0 0 5px'
                    }}>{listing.id} | {listing.brand} {listing.model}</h5>
                    <h5 style={{ padding: '3px 15px', backgroundColor: 'teal', color: '#fff', borderRadius: '0 5px 5px 0' }}>{listing.price} {listing.valyuta_short}</h5>
                </div>

            }
            actions={[
                <EyeOutlined onClick={() => setModal(true)} className={css.actionIcons} style={{ backgroundColor: 'blue' }} />,
                <Popover
                    content={
                        <div style={{ width: '200px' }}>
                            <Select style={{ width: '100%' }} defaultValue={listing.activeStatus} options={[
                                { label: 'Aktiv', value: 2 },
                                { label: 'Deaktiv', value: 1 },
                                { label: 'Block', value: 3 },
                                { label: "Muddati o'tgan", value: 4 }
                            ]} onChange={(st) => {
                                axios.put('update-listing/' + listing.id, { status: st }).then(res => {
                                    message.success("Status o'zgartirildi!!!")
                                    setRend(!rend)
                                })
                            }} />
                            <Input
                                type="date"
                                style={{ width: '100%', marginTop: '10px' }}
                                onChange={(e) => {
                                    axios.put('update-listingexp/' + listing.id, { expire_date: new Date(e.target.value).getTime() / 1000 }).then(res => { setRend(!rend) })
                                }}
                            />
                            <h4>Oxirgi muddati: {expDate.getDate() + "/" + (expDate.getMonth() + 1) + "/" + expDate.getFullYear()}</h4>
                        </div>
                    }
                >
                    <EditOutlined className={css.actionIcons} style={{ backgroundColor: 'green' }} />
                </Popover>,
                <Popover
                    content={<Space>
                        <Button type='primary' danger onClick={() => onDelete(listing.id)}>HA</Button>
                        <Button type='primary' onClick={() => setDelpopopen(false)}>Yo'q</Button>
                    </Space>}
                    title="O'chirishga ishinchingiz komilmi?"
                    trigger="click"
                    open={delpopopen}
                // onOpenChange={handleOpenChange}
                >
                    <DeleteOutlined onClick={() => setDelpopopen(true)} className={css.actionIcons} style={{ backgroundColor: 'red' }} />
                </Popover>
            ]}
        >
            <Image.PreviewGroup>
                {/* <Carousel speed={3000} style={{ maxWidth: '330px' }} autoplay> */}
                <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', overflow: 'auto', marginBottom: '10px' }}>

                    {listImages?.map(li => {
                        return (
                            <div key={li}>
                                <Image height={100} width={150} style={{ objectFit: 'cover', borderRadius: '5px' }} src={'https://avto-baraka.uz' + li} />
                            </div>
                        )
                    })}
                </div>
                {/* </Carousel> */}
            </Image.PreviewGroup>
            {payStatus ?
                null
                : <h3 style={{
                    backgroundColor: listing.sellerpaid ? 'green' : 'red',
                    color: '#fff',
                    fontWeight: '700',
                }}>Boshlang'ich To'lov holati: {listing.sellerpaid ? <span>To'langan</span> : <span>0</span>}</h3>}
            <Descriptions bordered={true} size='small' column={2} items={[
                {
                    key: '1',
                    label: <EyeOutlined />,
                    children: listing.viewed,
                },
                {
                    key: '2',
                    label: <CheckSquareOutlined />,
                    children: (listing.activeStatus === 2)
                        ? <span style={{ color: 'green', fontWeight: '700' }}>Active</span>
                        : (listing.activeStatus === 4)
                            ? <span style={{ color: 'orange', fontWeight: '700' }}>Muddati o'tgan</span>
                            : <span style={{ color: 'red', fontWeight: '700' }}>Deactive</span>,
                },
                {
                    key: '3',
                    label: <CalendarOutlined />,
                    children: t.getDate() + '/' + (t.getMonth() + 1) + '/' + t.getFullYear() + ' - ' + t.getHours() + ':' + t.getMinutes(),
                },
            ]} />

            <Descriptions size='small' column={1} items={[
                {
                    key: '1',
                    label: 'Yili:',
                    children: listing.year,
                },
                {
                    key: '2',
                    label: 'Manzili',
                    children: listing.region + " " + listing.district,
                },
                {
                    key: '3',
                    label: 'Yurgan masofasi',
                    children: listing.mileage,
                },
                {
                    key: '4',
                    label: "E'lon turi",
                    children: listing.car_type,
                },
            ]} />

            <Modal width={1200} title={listing.brand + ' ' + listing.model + ' | ' + listing.region + ' | ' + listing.car_type + ' ' + listing.district} open={modal} footer={false} onCancel={() => setModal(false)}>
                <Row gutter={25}>
                    <Col span={6}>
                        <Image.PreviewGroup>
                            <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', flexDirection: 'column' }}>
                                {listImages?.map(li => {
                                    return (
                                        <div key={li}>
                                            <Image height={150} width={250} style={{ objectFit: 'cover', borderRadius: '5px' }} src={'https://avto-baraka.uz' + li} />
                                        </div>
                                    )
                                })}
                            </div>
                        </Image.PreviewGroup>
                        <Divider />
                        <a href={'tel:+' + listing.phone}>
                            <Button type='primary' icon={<PhoneOutlined />} block>{listing.phone}</Button>
                        </a>
                    </Col>
                    <Col span={18}>
                        <div style={{ margin: '15px 0' }}>
                            <Select style={{ width: '100%' }} defaultValue={listing.activeStatus} options={[
                                { label: 'Aktiv', value: 2 },
                                { label: 'Deaktiv', value: 1 },
                                { label: 'Block', value: 3 },
                                { label: "Muddati o'tgan", value: 4 }
                            ]} onChange={(st) => {
                                axios.put('update-listing/' + listing.id, { status: st }).then(res => {
                                    message.success("Status o'zgartirildi!!!")
                                    setRend(!rend)
                                })
                            }} />
                        </div>

                        <Descriptions size='small' column={2} bordered items={[
                            {
                                key: '1',
                                label: 'Brend:',
                                children: listing.brand,
                            },
                            {
                                key: '1',
                                label: 'Kuzov turi:',
                                children: listing.car_body,
                            },
                            {
                                key: '1',
                                label: 'Positsiyasi:',
                                children: listing.car_position,
                            },
                            {
                                key: '1',
                                label: "Bo'lib to'lash:",
                                children: listing.credit,
                            },
                            {
                                key: '1',
                                label: 'Chegirma:',
                                children: listing.discount,
                            },
                            {
                                key: '1',
                                label: 'Joylovchi:',
                                children: listing.fullname,
                            },
                            {
                                key: '2',
                                label: 'Narxi',
                                children: listing.price + ' ' + listing.valyuta_name,
                            },
                            {
                                key: '2',
                                label: 'Yurgan masofasi',
                                children: listing.pulling_side,
                            },
                            {
                                key: '2',
                                label: 'Transmissiya turi',
                                children: listing.transmission,
                            },
                            {
                                key: '2',
                                label: 'Yoqilgi turi',
                                children: listing.type_of_fuel,
                            },
                            {
                                key: '3',
                                label: 'Yurgan masofasi',
                                children: listing.mileage,
                            },
                            {
                                key: '4',
                                label: "E'lon turi",
                                children: listing.car_type,
                            },
                            {
                                key: '4',
                                label: "Ishlab chiqarilgan yili",
                                children: listing.year,
                            },
                            {
                                key: '4',
                                label: "E'lon jolangan vaqt",
                                children: listing.year,
                            },
                            {
                                key: '1',
                                label: 'Batafsil',
                                children: listing.description,
                            },
                        ]} />
                    </Col>
                </Row>
            </Modal>
        </Card>
    );
}

export default ListingCard;

