import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import ListingCard from './ListingCard';
import axios from 'axios';
import css from './Listing.module.css'

function Deactive(props) {

    let [listings, setListings] = useState([])
    let [ls, setLs] = useState([])
    let [rend, setRend] = useState(false)
    let [payStatus, setPayStatus] = useState(false)

    useEffect(() => {
        axios.get('get-alllisting/uz').then(res => {
            if (res.status === 200) {
                setLs(res.data)
                setListings(res.data.filter(l => l.activeStatus === 1))
            }
        }).catch(error => {
            message.error("So'rovni yuborishda hatolik yuzaga keldi")
        })
        axios.get('getConfigs').then(res => {
            if (res.status === 200) {
                let data = res.data.filter(d => d.kalit === 'systemStartPayStatus')
                setPayStatus(Boolean(Number(data[0].qiymat)))
            }}).catch(error => {
                console.log(error);
            })

    }, [rend])

    let onChange = (v) => {
        switch (v) {
            case "all":
                setListings(ls)
                break;
            case 2:
                setListings(ls.filter(l => l.activeStatus === 2))
                break;
            case 1:
                setListings(ls.filter(l => l.activeStatus === 1))
                break;

            default:
                break;
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
                <h3>E'lonlar</h3>
                <Select onChange={onChange} defaultValue={'all'} size='large' style={{ width: '250px' }} options={[
                    { label: 'Hammasi', value: 'all' },
                    { label: 'Faqat Aktivlari', value: 2 },
                    { label: "Faqat Aktiv bo'lmaganlari", value: 1 },
                ]} />
            </div>

            <div className={css.gridBlock}>
                {(listings.length > 0)
                    ? listings?.map(l => <ListingCard payStatus={payStatus} key={l.id} listing={l} rend={rend} setRend={setRend} />)
                    : <h3 style={{ color: '#fff' }}>Ushbu parametr bo'yicha e'lonlar mavjud emas</h3>}
            </div>
        </>
    );
}

export default Deactive;