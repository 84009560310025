import { Button, Form, Input, message } from 'antd';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import React from 'react';
import logo from './../../assets/images/logo.png'
import orn from './../../assets/images/orn.svg'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useSignOut from 'react-auth-kit/hooks/useSignOut';

function Login() {
    const signIn = useSignIn();
    const signOut = useSignOut()
    let navigate = useNavigate()
    let onFinish = (v) => {
        axios.post('/login', v).then(res => {
            console.log(res)
            if ( signIn({
                    auth: {
                        token: res.data.access_token,
                        type: res.data.token_type
                    },
                    userState: res.data.user
                })) {
                    navigate('/dash')
            } else {
                signOut()
            }
        }).catch(err => message.error('Login yoki parol hato'))

    }
    let onFinishFailed = (v) => {
        console.log(v);
    }

    return (
        <div style={{
            backgroundImage: `url(${orn})`,
            backgroundSize: '800px',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '800px',
                    backgroundColor: '#fff',
                    padding: '25px',
                    borderRadius: '15px',
                    filter: 'drop-shadow(0px 0px 10px lightgray)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                // initialValues={{
                //     email: "a@b.c",
                //     password: "123"
                // }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <img src={logo} style={{ width: '150px' }} alt="Avto Baraka" />

                <Form.Item
                    label="Username"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Iltimos Login yoki emailni kiriting!',
                        },
                    ]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Iltimos Parolni kiriting!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Kirish
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Login;