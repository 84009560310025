import React, { useEffect } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import { useDistrict } from './DistrictStore';

function Districts() {
    let { columns, districts, setDistricts } = useDistrict()
    useEffect(setDistricts, [])
    return (
        <>
            <MainTable
                pageTitle={'Tumanlar'}
                pcolumns={columns}
                pdata={districts}
                defkey={'id'}
                xwidth={100}
            />
        </>
    );
}

export default Districts;