import {MainTable} from "../../../Components/MainTable/MainTable";
import {Add} from "./Add";
import {useCats} from "./CatState";
import {useEffect} from "react";
import {useMatches, useParams} from "react-router-dom";
import {Edit} from "./Edit";

export const Categorys = () => {
    let p = useParams()
    // console.log(p)
    let {categorys, columns, getCats, setEd, onDelete} = useCats()
    useEffect(() => {
        getCats(p.typeid)
    }, []);


    return (
        <>
            <MainTable
                pageTitle={"Kategoriyalar"}
                defkey={'id'}
                add={<Add ltid={p}/>}
                pdata={categorys}
                pcolumns={columns}
                edit={<Edit ltid={p}/>}
                onEdit={(rec)=>setEd(rec)}
                setEd={setEd}
                onDelete={onDelete}
            />
        </>
    )
}