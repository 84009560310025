import React, { useEffect, useState } from 'react';
import { useListingTypes } from './Store';
import { Form, Input, Button } from 'antd'
import OneImage from '../../Components/ImageComponents/OneImage';

function Edit({ ed }) {
    let [file, setFile] = useState('')
    let { editData } = useListingTypes()
    console.log(file);
    const onFinish = (values) => {
        values.icon = file.response.data.image
        editData(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    let [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(ed)
    }, [ed])

    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                  <Form.Item
                    label="ID"
                    name="id"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input />
                </Form.Item>


                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <OneImage setFile={setFile} />
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Edit;