import React, { useEffect } from 'react';
import { useRegions } from './Store';
import { MainTable } from '../../Components/MainTable/MainTable';

function Regions() {

    let { setRegions, regions, columns } = useRegions()
    useEffect(() => setRegions(), [])

    return (
        <>
            <MainTable
                pageTitle={'Viloyatlar'}
                pcolumns={columns}
                pdata={regions}
                defkey={'id'}
                xwidth={100}
            />
        </>
    );
}

export default Regions;