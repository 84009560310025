import {Button, Form, Input, message, Select} from "antd";
import React, {useEffect} from "react";
import {useCarBody} from "./CarBodyState";

export const Add = () => {

let {addBodys, ltypes, getLTypes} = useCarBody()

    useEffect(() => {
        getLTypes()
    }, []);
    const onFinish = (values) => {
        console.log('Success:', values);
        addBodys(values)
    };
    const onFinishFailed = (errorInfo) => {
        message.error("Ma'lumotlarni to'ldirishda hatolik");
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="E'lon turini tanlang"
                    name="listing_type_id"
                >
                    <Select options={ltypes.map(lt=>({label: lt.nameuz, value: lt.id}))} />
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}