import { useEffect } from "react";
import { MainTable } from "../../Components/MainTable/MainTable";
import { useSellers } from "./SellerState";

export const Sellers = () => {

    let { sellers, columns, getSellers, deleteSeller } = useSellers()

    useEffect(() => {
        getSellers()
    }, [])

    const onDelete = (_, rec) => {
        console.log(rec);
        deleteSeller(rec.id)
    }

    return (
        <>
            <MainTable
                pageTitle={"Tizim Foydalanuvchilari"}
                pcolumns={columns}
                pdata={sellers}
                defkey={'id'}
                edit={false}
                onDelete={onDelete}
            />
        </>
    )
}