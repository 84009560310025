import {Button, Col, Divider, Form, Input, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import login from "../../../Layouts/Login/Login";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import {useParams} from "react-router-dom";
import OneImage from "../../../Components/ImageComponents/OneImage";
import {useCats} from "../Categorys/CatState";
import {useModels} from "./ModelState";

export const Add = ({dopdata}) => {
    // let {categorys, getCats} = useCats()
    let [file, setFile] = useState()

    let {addModel} = useModels()

    // useEffect(() => {
    //     getCats(dopdata.ltid)
    // }, [dopdata])

    console.log(dopdata)
    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(v) => {
                    v.img = file.response.data.image
                    v.brand_id = dopdata.br
                    v.lt_id = dopdata.ltid
                    addModel(v, dopdata.ltid, dopdata.br)
                }}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row gutter={15}>
                    <Col span={12}>
                        <Form.Item
                            label="O'zbekcha nomi"
                            name="nameuz"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ruscha nomi"
                            name="nameru"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                </Row>
                <fieldset>
                    <legend>Parametrlar</legend>
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item
                                label="Tortuvchi tomoni"
                                name="pullingSide"
                            >
                                <Select options={[
                                    {
                                        value: "Oldi",
                                        label: "Oldi"
                                    },
                                    {
                                        value: "Orqasi",
                                        label: "Orqasi"
                                    },
                                    {
                                        value: "4x4",
                                        label: "4x4"
                                    },
                                    {
                                        value: "Boshqa",
                                        label: "Boshqa"
                                    },
                                ]}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ot Kuchi"
                                name="horsepower"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Eni (mm)"
                                name="x"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Uzunligi (mm)"
                                name="y"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Balandligi (mm)"
                                name="z"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Max tezlik (KM/S)"
                                name="maxspeed"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Klirans (mm)"
                                name="clirans"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Yukxona sig'imi (L)"
                                name="backpacksize"
                            >
                                <Input type={'number'}/>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Form.Item
                        label="Batafsil ma'lumot o'zbekcha"
                        name="descriptionuz"
                    >
                        <TextArea/>
                    </Form.Item>
                    <Form.Item
                        label="Batafsil ma'lumot Ruscha"
                        name="descriptionru"
                    >
                        <TextArea/>
                    </Form.Item>
                </fieldset>

                <Form.Item label="Rasmini yuklash">
                    <OneImage setFile={setFile}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>

        </>
    )
}