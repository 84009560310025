import React, { useEffect, useState } from 'react';
import { MainTable } from '../../Components/MainTable/MainTable';
import { useListingTypes } from './Store';
import Add from './Add';
import Edit from './Edit';

function ListingTypes() {
    let [ed, setEd] = useState({})
    let { columns, data, getData, delData } = useListingTypes()
    useEffect(getData, [])
    return (
        <>
            <MainTable
                pageTitle={"E'lon turlari"}
                pcolumns={columns}
                pdata={data}
                add={<Add />}
                defkey={'id'}
                onDelete={delData}
                edit={<Edit ed={ed} />}
                onEdit={(data) => setEd(data)}
                setEd={setEd}
            />
        </>
    );
}

export default ListingTypes;