import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useBrand } from "./State";
import OneImage from "../../Components/ImageComponents/OneImage";
import TextArea from "antd/es/input/TextArea";

export const Edit = ({ ed }) => {
    let [file, setFile] = useState('')
    let { editData } = useBrand()
    const onFinish = (values) => {
        values.logo = file.response.data.image
        editData(values)
    };

    let [form] = Form.useForm()
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.setFieldsValue(ed)
    }, [ed])

    return (
        <>
            <Form
                name="basic"
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="id"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input />

                </Form.Item>
                <Form.Item
                    label="Logotip"
                    name="logo"
                >
                    <OneImage setFile={setFile} />
                </Form.Item>
                <Form.Item
                    label="Batafsil ma'lumot"
                    name="descriptionuz"
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    label="Подробная информация"
                    name="descriptionru"
                >
                    <TextArea />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}