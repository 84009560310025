import {create} from "zustand";
import axios from "axios";
import {message} from "antd";

export let useCats = create((set, get) => ({
    categorys: [],
    ed: {},
    columns: [
        {
            title: "O'zbekcha nomi",
            dataIndex: 'nameuz',
            key: 'nameuz'
        },
        {
            title: "Ruscha nomi",
            dataIndex: 'nameru',
            key: 'nameru'
        },
    ],
    getCats: (ltid) => {
        axios.get('categories/' + ltid).then(res => {
            if (res.status === 200) {
                set({categorys: res.data})
            }
        })
    },
    addCategory: (v, ltid) => {
        v.listing_type_id = ltid
        axios.post('categories', v).then(res => {
            if (res.status === 200) {
                get().getCats(ltid)
            }
        })
    },
    editCat: (v, ltid) => {
        v.listing_type_id = ltid
        axios.put('categories/' + v.id, v).then(res => {
            if (res.status === 200) {
                get().getCats(ltid)
            }
        })
    },
    onDelete: (_, rec) => {
        axios.delete('categories/' + rec.id).then(res => {
            if (res.status === 200) {
                get().getCats(rec.listing_type_id)
            }
        })
    },
    setEd: (e) => {
        set({ed: e})
    },
    onFinishFailed: (error) => {
        message.error("Ma'lumotlarni kiritishda hatolik")
    }

}))