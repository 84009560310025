import { NotificationOutlined } from '@ant-design/icons';
import { Badge, Button, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Notif({ audioStatus, audio }) {
    let [listCount, setListCount] = useState(0)

    useEffect(() => {
        let fetchData = () => {

            axios.get('listcount').then(res => {
                setListCount(res.data.deactive)
                if (res.status === 200) {
                    let lc = localStorage.getItem('listCount')
                    if (Number(lc) !== Number(res.data.ls)) {
                        localStorage.setItem('listCount', res.data.ls)
                        if (audioStatus) {
                            audio.play()
                        }
                    }
                }
            }).catch(error => {
                // message.error("Ma'lumotlarni olishda muammo yuzaga keldi!!!")
                console.log(error);
            })
        }

        const interval = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(interval);
    }, [listCount, audio]);

    return (
        <>

            <Badge count={listCount} size='small'>
                <Link to='/dash/deactive'>
                    <Button type="primary" icon={<NotificationOutlined />} />
                </Link>
            </Badge>
        </>
    );
}

export default Notif;