import {UserActivPay} from "./UserActivPay";
import {Divider} from "antd";
import {Tarifs} from "./Tarifs";

export const Pays = () => {
    return (
        <>
            <h2>To'lovlar Sozlamalari</h2>
            <UserActivPay/>
            <Divider />
            <Tarifs />
        </>
    )
}