import { message } from "antd";
import axios from "axios";
import { create } from "zustand";

export let useRegions = create((set, get) => ({
    regions: [],
    columns: [
        {
            title: "Nomi o'zbek tilida",
            dataIndex: 'nameuz',
            key: 'nameuz',
        },
        {
            title: 'Название на русском',
            dataIndex: 'nameru',
            key: 'nameru',
        },
    ],
    setRegions: () => {
        axios.get('/regions').then(res => set({ regions: res.data })).catch(() => message.error("Ma'lumotni olishda hatolik yuzaga keldi"))
    }
}))