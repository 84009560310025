import {MainTable} from "../../Components/MainTable/MainTable";
import {Add} from "./Add";
import {Edit} from "./Edit";
import {useEffect, useState} from "react";
import {useFuelTypes} from "./State";

export const TypeOfFuels = () => {
    let [ed, setEd] = useState({})
    let {data, columns, getData, delData} = useFuelTypes();
    useEffect(getData, [])
    return (
        <>
            <MainTable
                pdata={data}
                pcolumns={columns}
                pageTitle={"Yoqilg'i turlari"}
                defkey={'id'}
                add={<Add/>}
                onDelete={delData}
                xwidth={1000}
                onEdit={(data) => setEd(data)}
                edit={<Edit ed={ed}/>}
                setEd={setEd}
            />
        </>
    )
}