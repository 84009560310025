import {useParams} from "react-router-dom";
import {Button, Form, Select} from "antd";
import {useLBrand} from "./LBrand";
import {useEffect} from "react";

export const Add = () => {

    let {brands, lbrands,getBrands, setLBrands} = useLBrand()

    useEffect(() => {
        getBrands()
    }, []);

    let p = useParams()
    // console.log(p.typeid)

    return (
        <>
            <Form
                labelCol={{span: 24}}
                wrapperCol={{span: 16}}
                onFinish={(v) => {
                    v.ltype_id = p.typeid
                    setLBrands(v, p.typeid)
                }}
            >
                <Form.Item
                    label={"Kategoriyalarni tanlang"}
                    name={"brand_id"}
                >
                    <Select
                        options={brands.map(b => ({label: b.nameuz, value: b.id}))}
                        mode={'multiple'}
                        placeholder={"Barcha kerakli brendlarni tanlang"}
                        defaultValue={lbrands.map(l=>l.id)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'}>Saqlash</Button>
                </Form.Item>
            </Form>
        </>
    )
}