import {useParams} from "react-router-dom";
import {useModels} from "./ModelState";
import {useEffect, useState} from "react";
import {Avatar, Button, Card, Col, Drawer, Image, List, Modal, Row, Select} from "antd";
import {MainTable} from "../../../Components/MainTable/MainTable";
import {useBrand} from "../../Brands/State";
import css from './model.module.css'
import {Add} from "./Add";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import {Modifications} from "./Modifications";

export const Models = () => {
    let p = useParams()
    let {data, getData} = useBrand()
    let {models, getModels} = useModels()
    let [brand, setBrand] = useState(0)
    let [dopen, setDopen] = useState(false)
    let [mopen, setMopen] = useState(false)
    let [currentModel, setCurrentModel] = useState(1)
    useEffect(() => {
        getModels(p.typeid, brand)
    }, [brand]);

    useEffect(() => {
        getData()
    }, [])


    let onChangeBrand = (b) => {
        setBrand(b.id)
    }

    return (
        <>
            <Row gutter={15}>
                <Col span={6}>
                    <List
                        dataSource={data}
                        renderItem={item => (
                            <List.Item className={css.listitem} onClick={() => onChangeBrand(item)}
                                       style={{cursor: 'pointer'}}>
                                <List.Item.Meta
                                    avatar={<Avatar src={'https://avto-baraka.uz' + item.logo} style={{
                                        objectFit: 'contain',
                                        border: '1px solid silver',
                                        alignItems: 'center'
                                    }}/>}
                                    title={item.nameuz}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={18}
                     style={{padding: '15px', boxShadow: 'inset 0px 0px 15px -5px gray', borderRadius: '10px', height: '76vh', overflow: 'auto'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px'}}>
                        <h4>Tanlangan brend uchun modellar</h4>
                        <Button onClick={()=>setDopen(true)} icon={<PlusOutlined />} type={'primary'} size={'small'} />
                    </div>
                    <div style={{display: 'grid', gap: '15px', gridTemplateColumns: 'repeat(4, 1fr)'}}>
                        {
                            models?.map(model=> <Card
                                key={model.id}
                                cover={
                                    <img
                                        alt="example"
                                        src={'https://avto-baraka.uz' + model.img}
                                        style={{height: '150px', objectFit: 'cover'}}
                                    />
                                }
                                actions={[
                                    <EyeOutlined onClick={()=>{
                                        setMopen(true)
                                        setCurrentModel(model.id)
                                    }} key="setting" style={{color: 'green'}} />,
                                    <DeleteOutlined key="edit" style={{color: 'red'}} />,
                                ]}
                                style={{overflow: 'hidden'}}
                            >
                                <h4>{model.nameuz}</h4>

                            </Card>)
                        }
                    </div>
                </Col>
            </Row>

            <Drawer open={dopen} title={"Model qo'shish"} onClose={()=>setDopen(false)}>
                <Add dopdata={{ltid: p.typeid, br: brand }} />
            </Drawer>

            <Modal open={mopen} onCancel={()=>setMopen(false)} footer={false} width={1200} title={"-"}>
                <Modifications model={currentModel} />
            </Modal>

        </>
    )
}