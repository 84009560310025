import {MainTable} from "../../Components/MainTable/MainTable";
import {useColors} from "./State";
import {useEffect, useState} from "react";
import {Add} from "./Add";
import {Edit} from "./Edit";

export const Colors = () => {
    let [ed, setEd] = useState({})
    let {data, columns, getData, delData, editData } = useColors();
    useEffect(getData, [])
    return (
        <>
            <MainTable
                pageTitle={'Ranglar'}
                defkey={'id'}
                pdata={data}
                pcolumns={columns}
                add={<Add/>}
                onDelete={delData}
                xwidth={1000}
                onEdit={(data) => setEd(data)}
                edit={<Edit ed={ed} />}
                setEd={setEd}
            />
        </>
    )
}