import React, { useState } from 'react';
import { Form, Input, Button } from 'antd'
import OneImage from '../../Components/ImageComponents/OneImage';
import { useListingTypes } from './Store';
function Add() {
    let [file, setFile] = useState('')

    let { setData } = useListingTypes()
    console.log(file);
    const onFinish = (values) => {
        values.icon = file.response.data.image
        console.log('Success:', values);
        setData(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input />
                </Form.Item>


                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <OneImage setFile={setFile} />
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Add;