import { Button, Form, Input, Space } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function Credit() {
    let [creditType, setCreditType] = useState([])
    let [rend, setRend] = useState(false)

    useEffect(() => {
        axios.get('getConfigs').then(res => {
            if (res.status === 200) {
                let data = res.data.filter(d => d.kalit !== 'systemStartPayStatus')
                setCreditType(data)
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])
    // console.log(creditType);

    // $router->get('', 'ConfigController@index');
    // $router->post('updateConfigs', 'ConfigController@update');

    let onFinish = (values, kalit) => {
        let data = {
            kalit: kalit,
            qiymat: values[kalit]
        }
        console.log(data);
        axios.post('updateConfigs', data).then(res => {
            if (res.status === 200) {
                setRend(!rend)
            }
        }).catch(error => {
            console.log(error);
        })
    }


    let texttoName = (text) => {
        switch (text) {
            case 'kridet_yillik_foiz':
                return 'Kreditning yillik foizi (%)'
                break;
            case 'kridet_dollor_sotish':
                return 'AQSH dollari sotish kursi'
                break;
            case 'kridet_dollor_olish':
                return 'AQSH dollari olish kursi'
                break;
            case 'natarius':
                return 'Notarius xizmati'
                break;
            case 'dillerFoiz':
                return 'Diller foizi (%)'
                break;
            case 'firmaFoiz':
                return "Korporativlar uchun foiz (%)"
                break;
            case 'birinchiTulovFoiz':
                return 'Boshlang`ich to`lov foizi (%)'
                break;
            default:
                return text
        }
    }



    return (
        <>
            <h1>Kredit sozlamalari</h1>
            <table width={'100%'}>
                <thead>
                    <tr>
                        <th>Parametr nomi</th>
                        {/* <th>Hozirgi qiymati</th> */}
                        <th>O'zgartirish formasi</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        creditType?.map(c => (
                            <tr key={c.id}>
                                <td>
                                    <h3>{texttoName(c.kalit)}</h3>
                                </td>
                                {/* <td style={{ textAlign: 'right', paddingRight: '25px' }}>
                                    <h3>{c.qiymat}</h3>
                                </td> */}
                                <td>
                                    <Form
                                        name={c.kalit}
                                        initialValues={{ remember: true }}
                                        onFinish={(v)=>onFinish(v, c.kalit)}
                                    // onFinishFailed={onFinishFailed}

                                    >
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 80px',
                                            gap: '10px',
                                            width: '100%'
                                        }}>
                                            <Form.Item
                                                style={{ marginBottom: '0' }}
                                                width={'100%'}
                                                initialValue={c.qiymat}
                                                name={c.kalit}
                                                rules={[{ required: true, message: `Iltimos, ${c.kalit}ni kiriting!` }]}
                                            >
                                                <Input width={'100%'} />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: '0' }}
                                            >
                                                <Button type="primary" htmlType="submit">Saqlash</Button>
                                            </Form.Item>
                                        </div>

                                    </Form>
                                </td>
                            </tr>
                        ))

                    }

                </tbody>
            </table >
        </>
    );
}

export default Credit;