import {MainTable} from "../../Components/MainTable/MainTable";
import {useCarBody} from "./CarBodyState";
import {useEffect} from "react";
import {Add} from "./Add";
import {Edit} from "./Edit";

export const CarBody = () => {

    let {carbodys, setCarBodys,columns, onDelete, setEd, edit} = useCarBody()

    useEffect(() => {
        setCarBodys()
    }, []);

    let onEdit = (ed)=>{
        // console.log(ed)
        setEd(ed)
    }

    return (
        <>
            <MainTable
                pageTitle={"Kuzov turi"}
                pdata={carbodys}
                pcolumns={columns}
                add={<Add />}
                onDelete={onDelete}
                edit={<Edit />}
                onEdit={onEdit}
                setEd={setEd}
                defkey={'id'}
            />
        </>
    )
}
    // nameuz nameru logo  descriptionuz descriptionru listing_type_id
