import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Layouts/Login/Login";
import axios from "axios";
import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit/AuthProvider";
import Dash from "./Layouts/Dashboard/Dash";

import RequireAuth from '@auth-kit/react-router/RequireAuth'
import { message } from "antd";


function App() {
  let navigate = useNavigate()
  axios.defaults.baseURL = 'https://avto-baraka.uz/api'
  axios.interceptors.response.use(function (response) {
    // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
    // Здесь можете сделать что-нибудь с ответом
    return response;
  }, function (error) {
    console.log(error);
    if (error.response.status === 401) {
      navigate('login')
    } else {
      message.error("Ma'lumotlarni olishda muammo yuzaga keldi!!!")
    }
    return Promise.reject(error);
  });

  const store = createStore({
    authName: '_auth',
    authType: 'localStorage',
  });

  let listCounts = localStorage.getItem('listCount')
  if (listCounts === null || listCounts === undefined) {
    localStorage.setItem('listCount', 0)
  }


  return (
    <AuthProvider store={store}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path={'/dash/*'} element={
          <RequireAuth fallbackPath={'/'}>
            <Dash />
          </RequireAuth>
        }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
