import {MainTable} from "../../../Components/MainTable/MainTable";
import {useLBrand} from "./LBrand";
import {useEffect} from "react";
import {Add} from "./Add";
import {useParams} from "react-router-dom";
import axios from "axios";

export const ListToBrand = () => {
    let {lbrands, getLBrands, columns, onDel} = useLBrand()
    let p = useParams()
    useEffect(() => {
        getLBrands(p.typeid)
    }, []);
    let onDelete = (_, rec) => {
        console.log(rec)
        onDel(rec.lb, p.typeid)
    }
    return (
        <>
            <MainTable
                pcolumns={columns}
                pdata={lbrands}
                defkey={'id'}
                pageTitle={"E'lon turiga taaluqli brendlar ro'yxati"}
                add={<Add/>}
                onDelete={onDelete}
                xwidth={1000}
                hideedbtn={true}
            />
        </>
    )
}