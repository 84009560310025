import {Button, Form, Input, Select} from "antd";
import React, {useEffect} from "react";
import {useCarBody} from "./CarBodyState";
import {useForm} from "antd/es/form/Form";

export const Edit = () => {

    let {ltypes, edit, ed, getLTypes}=useCarBody()

    let [form] = useForm()

    useEffect(() => {
        getLTypes()
        form.setFieldsValue(ed)
    }, [ed]);


    const onFinish = (values) => {
        edit(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="O'zbekcha nomi"
                    name="id"
                    hidden={true}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="O'zbekcha nomi"
                    name="nameuz"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Ruscha nomi"
                    name="nameru"
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="E'lon turini tanlang"
                    name="listing_type_id"
                >
                    <Select options={ltypes.map(lt => ({label: lt.nameuz, value: lt.id}))}/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Saqlash
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}